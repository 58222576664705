<template>
  <v-card class="py-5">
    <v-card-title class="justify-center text-h4 pb-10 primary--text"
      >เลือกการชำระเงิน</v-card-title
    >
    <v-card-text class="popup-background">
      <v-spacer />
      <v-row justify="center">
        <v-col cols="3" class="mt-3">
          <DatePicker
            label="วันที่ชำระ"
            v-model="form.date"
            @onChange="(val) => (form.date = val)"
          />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="4" class="text-center">
          <v-btn
            class="py-7 px-10"
            :color="form.type == 1 ? '#E0E0E0' : 'white'"
            @click="() => (form.type = 1)"
            >เงินสด</v-btn
          >
        </v-col>
        <v-col cols="4" class="text-center">
          <v-btn
            class="py-7 px-10"
            :color="form.type == 2 ? '#E0E0E0' : 'white'"
            @click="() => (form.type = 2)"
            >โอน</v-btn
          >
        </v-col>
        <v-col cols="4" class="text-center">
          <v-btn
            class="py-7 px-10"
            :color="form.type == 3 ? '#E0E0E0' : 'white'"
            @click="() => (form.type = 3)"
            >บัตรเครดิต</v-btn
          >
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="py-5">
      <v-btn outlined class="px-5 ml-10" color="grey" @click="$emit('onClose')"
        >ปิด</v-btn
      >
      <v-spacer />
      <v-btn outlined class="px-5 ml-10" color="error" @click="pay()"
        >จ่าย</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue';
import * as moment from 'moment';
export default {
  components: {
    DatePicker,
  },
  props: {
    item_id: {
      required: false,
      type: String,
      default: '',
    },
  },
  data: () => ({
    form: {
      expense_id: '',
      type: 0,
      reference: '',
      note: '',
      date: moment().format('YYYY-MM-DDTHH:mm:ss'),
    },
  }),
  async created() {
    console.log('ExpensePayChoose');
    this.form.expense_id = this.item_id;
  },
  methods: {
    async pay() {
      if ((this.form.type == 0)) {
        this.$alertServerError({ title: `กรุณาเลือกช่องทางการชำระ!`, text: `` });
      } else {
        let body = {
          token: this.$jwt.sign(this.form, this.$privateKey, {
            noTimestamp: true,
          }),
        };
        await this.$axios
          .post(`${this.$baseUrl}/expense/pay_expense`, body)
          .then(async (res) => {
            console.log(res);
            this.$emit('onSuccess');
          })
          .catch((err) => {
            console.log('err', err);
          });
      }
    },
  },
};
</script>
